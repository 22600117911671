import { z } from 'zod'
import { i18n, t } from '../../utils'

export const AccessKeysi18nFields = {
  number: t('number'),
  property: t('unit'),
  owners: t('tenants-uppercase'),
}

export const AccessKeysSchema = z.object({
  number: z
    .string({ required_error: i18n.t('input-is-required', { field: AccessKeysi18nFields.number }) })
    .min(1, { message: i18n.t('input-is-required', { field: AccessKeysi18nFields.number }) }),
  property: z
    .object({
      id: z.string().nullable(),
    })
    .optional(),
  owners: z.array(z.object({ id: z.string() })).optional(),
})

export const accessKeysUpdateSchema = AccessKeysSchema.extend({ id: z.string() })

export type AccessKeys = z.infer<typeof AccessKeysSchema>
export type AccessKeysUpdate = z.infer<typeof accessKeysUpdateSchema>
