import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Posti18nFields = {
  projects: t('project-s'),
  coverImage: t('cover-image'),
  title: t('title'),
  description: t('description'),
  segmentId: t('group'),
  sendPostAt: t('custom-date'),
  attachments: t('files'),
}

const basePostSchema = z.object({
  coverImage: z.any().optional(),
  title: z
    .string({ required_error: i18n.t('input-is-required', { field: Posti18nFields.title }) })
    .min(1, { message: i18n.t('input-is-required', { field: Posti18nFields.title }) }),
  description: z.string().optional(),
  sendPostAt: z.date().optional(),
  attachments: z.any().optional(),
  audience: z
    .array(z.object({ id: z.string({ required_error: i18n.t('input-is-required', { field: t('audience') }) }) }))
    .optional(),
})

export const postSingleProjectSchema = basePostSchema.extend({
  segment: z
    .object({ id: z.string({ required_error: i18n.t('input-is-required', { field: Posti18nFields.segmentId }) }) })
    .optional(),
  segments: z
    .array(
      z.object({ id: z.string({ required_error: i18n.t('input-is-required', { field: Posti18nFields.segmentId }) }) }),
    )
    .optional(),
  properties: z
    .array(z.object({ id: z.string({ required_error: i18n.t('input-is-required', { field: t('properties') }) }) }))
    .optional(),
})

export const postAllProjectsSchema = basePostSchema.extend({
  projects: z
    .array(z.object({ id: z.string() }), {
      required_error: i18n.t('input-is-required', { field: Posti18nFields.projects }),
    })
    .min(1, i18n.t('input-is-required', { field: Posti18nFields.projects })),
})

export const postEmailPreviewSchema = z.object({
  email: z.string({ required_error: t('invalid-email') }).email({ message: t('invalid-email') }),
})

export type PostSingleProjectFormSchema = z.infer<typeof postSingleProjectSchema>
export type PostAllProjectsFormSchema = z.infer<typeof postAllProjectsSchema>
