import * as React from 'react'
import { createBrowserRouter, redirect, type RouteObject } from 'react-router-dom'
import { DashboardLayout } from '../layouts/DashboardLayout'
import { ErrorPage } from '../pages/error-page'
import { RootProvider } from '../providers/RootProvider'
import { allProjectsSettingsLoader } from '../utils/loaders/allProjectsSettingsLoader'
import { authenticationLoader } from '../utils/loaders/authenticationLoader'
import { devOnlyLoader } from '../utils/loaders/devOnlyLoader'
import { editPermissionLoader } from '../utils/loaders/editPermissionLoader'
import { healthCheckLoader } from '../utils/loaders/healthCheckLoader'
import { isCondoProjectLoader } from '../utils/loaders/isCondoProjectLoader'
import { loginLoader } from '../utils/loaders/loginLoader'
import { unauthenticatedLoader } from '../utils/loaders/unauthenticatedLoader'
import { viewPermissionLoader } from '../utils/loaders/viewPermissionLoader'

const AUTH_ROUTES: RouteObject[] = [
  {
    path: 'login',
    loader: loginLoader,
    lazy: async () => {
      const Login = await import('./auth/login')
      return { Component: Login.default }
    },
  },
  {
    path: 'register',
    loader: unauthenticatedLoader,
    lazy: async () => {
      const Register = await import('./auth/register')
      return { Component: Register.default }
    },
  },
  {
    path: 'forgot-password',
    lazy: async () => {
      const ForgotPassword = await import('./auth/forgot-password')
      return { Component: ForgotPassword.default }
    },
  },
  {
    path: 'reset-password',
    lazy: async () => {
      const ResetPassword = await import('./auth/reset-password')
      return { Component: ResetPassword.default }
    },
  },
  {
    path: '*',
    loader: () => {
      throw redirect('/auth/login')
    },
  },
]

const DASHBOARD_ROUTES: RouteObject[] = [
  {
    path: ':projectId/chat',
    loader: viewPermissionLoader('CHAT'),
    lazy: async () => {
      const Chat = await import('./chat')
      return { Component: Chat.default }
    },
    children: [
      {
        path: ':channelUrl',
        lazy: async () => {
          const ChannelUrl = await import('./chat/:channelUrl')
          return { Component: ChannelUrl.default }
        },
        children: [
          {
            path: 'units/:propertyId/edit',
            loader: viewPermissionLoader('UNITS'),
            lazy: async () => {
              const { EditUnit } = await import('../components/drawer/Unit/EditUnit')
              return { Component: EditUnit }
            },
          },
          {
            path: 'units/:propertyId/details',
            loader: viewPermissionLoader('UNITS'),
            lazy: async () => {
              const { ViewUnit } = await import('../components/drawer/Unit/ViewUnit')
              return { Component: ViewUnit }
            },
          },
          {
            path: 'residents/:residentId/edit',
            loader: viewPermissionLoader('USER'),
            lazy: async () => {
              const { EditResident } = await import('../components/drawer/residentProfile/EditResident')
              return { Component: EditResident }
            },
          },
          {
            path: 'residents/:residentId/details',
            loader: viewPermissionLoader('USER'),
            lazy: async () => {
              const { ViewResident } = await import('../components/drawer/residentProfile/ViewResident')
              return { Component: ViewResident }
            },
          },
          {
            path: 'notifications',
            loader: viewPermissionLoader('NOTIFICATION'),
            lazy: async () => {
              const { ResidentNotifications } = await import('../components/drawer/ResidentNotifications')
              return { Component: ResidentNotifications }
            },
          },
          {
            path: 'reservations/create',
            loader: viewPermissionLoader('RESERVATIONS'),
            lazy: async () => {
              const CreateReservation = await import('./chat/:channelUrl/reservations/create')
              return { Component: CreateReservation.default }
            },
          },
          {
            path: 'reservations/:reservationId/edit',
            loader: viewPermissionLoader('RESERVATIONS'),
            lazy: async () => {
              const EditReservation = await import('./chat/:channelUrl/reservations/edit')
              return { Component: EditReservation.default }
            },
          },
          {
            path: 'packages/create',
            lazy: async () => {
              const CreatePackage = await import('./chat/:channelUrl/packages/create')
              return { Component: CreatePackage.default }
            },
          },
          {
            path: 'packages/:packageId/edit',
            lazy: async () => {
              const EditPackage = await import('./chat/:channelUrl/packages/:packageId/edit')
              return { Component: EditPackage.default }
            },
          },
          {
            path: 'tasks/create',
            loader: viewPermissionLoader('TASK'),
            lazy: async () => {
              const CreateTask = await import('../components/tasks/CreateTask')
              return { Component: CreateTask.default }
            },
          },
          {
            path: 'tasks/details/:taskId',
            loader: viewPermissionLoader('TASK'),
            lazy: async () => {
              const TaskDetails = await import('../components/tasks/TaskDetails')
              return { Component: TaskDetails.default }
            },
          },
        ],
      },
    ],
  },
  {
    path: ':projectId/tasks',
    loader: viewPermissionLoader('TASK'),
    lazy: async () => {
      const Tasks = await import('./tasks')
      return { Component: Tasks.default }
    },
    children: [
      {
        path: 'create',
        lazy: async () => {
          const CreateTask = await import('../components/tasks/CreateTask')
          return { Component: CreateTask.default }
        },
      },
      {
        path: 'details/:taskId',
        lazy: async () => {
          const TaskDetails = await import('../components/tasks/TaskDetails')
          return { Component: TaskDetails.default }
        },
      },
      {
        path: 'details/:taskId/units/:propertyId/details',
        lazy: async () => {
          const { ViewUnit } = await import('../components/drawer/Unit/ViewUnit')
          return { Component: ViewUnit }
        },
      },
      {
        path: 'details/:taskId/units/:propertyId/edit',
        lazy: async () => {
          const { EditUnit } = await import('../components/drawer/Unit/EditUnit')
          return { Component: EditUnit }
        },
      },
      {
        path: 'details/:taskId/residents/:residentId/edit',
        lazy: async () => {
          const { EditResident } = await import('../components/drawer/residentProfile/EditResident')
          return { Component: EditResident }
        },
      },
      {
        path: 'details/:taskId/residents/:residentId/details',
        lazy: async () => {
          const { ViewResident } = await import('../components/drawer/residentProfile/ViewResident')
          return { Component: ViewResident }
        },
      },
    ],
  },
  {
    path: ':projectId/news',
    loader: viewPermissionLoader('NEWS'),
    children: [
      {
        index: true,
        lazy: async () => {
          const News = await import('./news')
          return { Component: News.default }
        },
      },
      {
        path: 'new',
        lazy: async () => {
          const NewNews = await import('./news/new')
          return { Component: NewNews.default }
        },
      },
      {
        path: ':postId',
        lazy: async () => {
          const { NewsDetailsLayout } = await import('../layouts/NewsDetailsLayout')
          return { Component: NewsDetailsLayout }
        },
        children: [
          {
            path: 'content',
            lazy: async () => {
              const Content = await import('./news/:postId/content')
              return { Component: Content.default }
            },
          },
          {
            path: 'audience',
            lazy: async () => {
              const Audience = await import('./news/:postId/audience')
              return { Component: Audience.default }
            },
          },
        ],
      },
    ],
  },
  {
    path: ':projectId/calendar',
    loader: viewPermissionLoader('CALENDAR'),
    lazy: async () => {
      const Calendar = await import('./calendar')
      return { Component: Calendar.default }
    },
  },
  {
    path: ':projectId/reservations',
    loader: viewPermissionLoader('RESERVATIONS'),
    lazy: async () => {
      const { ReservationsLayout } = await import('../layouts/ReservationsLayout')
      return { Component: ReservationsLayout }
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { ReservationsTable } = await import('../components/reservation/ReservationsTable')
          return { Component: ReservationsTable }
        },
      },
      {
        path: 'calendar',
        lazy: async () => {
          const { ReservationsCalendar } = await import('../components/reservation/ReservationsCalendar')
          return { Component: ReservationsCalendar }
        },
      },
    ],
  },

  {
    path: ':projectId/marketplace',
    lazy: async () => {
      const Marketplace = await import('./marketplace')
      return { Component: Marketplace.default }
    },
  },
  {
    path: ':projectId/packages',
    lazy: async () => {
      const Packages = await import('./packages')
      return { Component: Packages.default }
    },
  },
  {
    path: ':projectId/residents',
    loader: viewPermissionLoader('USER'),
    lazy: async () => {
      const Residents = await import('./residents')
      return { Component: Residents.default }
    },
    children: [
      {
        path: 'new',
        lazy: async () => {
          const { CreateResident } = await import('../components/drawer/residentProfile/CreateResident')
          return { Component: CreateResident }
        },
      },
      {
        path: ':residentId/edit',
        lazy: async () => {
          const { EditResident } = await import('../components/drawer/residentProfile/EditResident')
          return { Component: EditResident }
        },
      },
      {
        path: ':residentId/details',
        lazy: async () => {
          const { ViewResident } = await import('../components/drawer/residentProfile/ViewResident')
          return { Component: ViewResident }
        },
      },
      {
        path: ':residentId/details/units/:propertyId/details',
        lazy: async () => {
          const { ViewUnit } = await import('../components/drawer/Unit/ViewUnit')
          return { Component: ViewUnit }
        },
      },
      {
        path: ':residentId/details/units/:propertyId/edit',
        lazy: async () => {
          const { EditUnit } = await import('../components/drawer/Unit/EditUnit')
          return { Component: EditUnit }
        },
      },
    ],
  },
  {
    path: ':projectId/units',
    loader: viewPermissionLoader('UNITS'),
    lazy: async () => {
      const Units = await import('./units')
      return { Component: Units.default }
    },
    children: [
      {
        path: 'new',
        lazy: async () => {
          const { CreateUnit } = await import('../components/drawer/Unit/CreateUnit')
          return { Component: CreateUnit }
        },
      },
      {
        path: ':propertyId/edit',
        lazy: async () => {
          const { EditUnit } = await import('../components/drawer/Unit/EditUnit')
          return { Component: EditUnit }
        },
      },
      {
        path: ':propertyId/details',
        lazy: async () => {
          const { ViewUnit } = await import('../components/drawer/Unit/ViewUnit')
          return { Component: ViewUnit }
        },
      },
      {
        path: ':propertyId/details/residents/:residentId/edit',
        lazy: async () => {
          const { EditResident } = await import('../components/drawer/residentProfile/EditResident')
          return { Component: EditResident }
        },
      },
      {
        path: ':propertyId/details/residents/:residentId/details',
        lazy: async () => {
          const { ViewResident } = await import('../components/drawer/residentProfile/ViewResident')
          return { Component: ViewResident }
        },
      },
      {
        path: ':propertyId/edit/residents/:residentId/details',
        lazy: async () => {
          const { ViewResident } = await import('../components/drawer/residentProfile/ViewResident')
          return { Component: ViewResident }
        },
      },
    ],
  },
  {
    path: ':projectId/files',
    loader: viewPermissionLoader('FILE'),
    children: [
      {
        index: true,
        lazy: async () => {
          const Files = await import('./files')
          return { Component: Files.default }
        },
      },
      {
        path: ':folderId',
        lazy: async () => {
          const Files = await import('./files')
          return { Component: Files.default }
        },
      },
    ],
  },
  {
    path: ':projectId/amenities',
    loader: viewPermissionLoader('AMENITY'),
    children: [
      {
        index: true,
        lazy: async () => {
          const Amenities = await import('./amenities')
          return { Component: Amenities.default }
        },
      },
      {
        path: 'new',
        lazy: async () => {
          const NewAmenity = await import('./amenities/new')
          return { Component: NewAmenity.default }
        },
      },
      {
        path: ':amenityId',
        lazy: async () => {
          const AmenityId = await import('./amenities/:amenityId')
          return { Component: AmenityId.default }
        },
      },
    ],
  },

  {
    path: ':projectId/contacts',
    loader: viewPermissionLoader('CONTACT'),
    lazy: async () => {
      const Contacts = await import('./contacts')
      return { Component: Contacts.default }
    },
  },
  {
    path: ':projectId/rules',
    loader: viewPermissionLoader('RULE'),
    lazy: async () => {
      const Rules = await import('./rules')
      return { Component: Rules.default }
    },
  },
  {
    path: ':projectId/faq',
    loader: viewPermissionLoader('FAQ'),
    lazy: async () => {
      const Faq = await import('./faq')
      return { Component: Faq.default }
    },
  },
  {
    path: ':projectId/groups',
    loader: viewPermissionLoader('SEGMENT'),
    children: [
      {
        index: true,
        lazy: async () => {
          const Groups = await import('./groups')
          return { Component: Groups.default }
        },
      },
      {
        path: 'new',
        lazy: async () => {
          const NewGroup = await import('./groups/new')
          return { Component: NewGroup.default }
        },
      },
      {
        path: ':segmentId',
        lazy: async () => {
          const SegmentId = await import('./groups/:segmentId')
          return { Component: SegmentId.default }
        },
      },
    ],
  },
  {
    path: ':projectId/thirdPartySetting',
    loader: viewPermissionLoader('THIRD_PARTY_SYNCHRONIZATION'),
    lazy: async () => {
      const ThirdPartySetting = await import('./thirdPartySetting')
      return { Component: ThirdPartySetting.default }
    },
  },
  {
    path: ':projectId/profile',
    lazy: async () => {
      const Profile = await import('./profile')
      return { Component: Profile.default }
    },
  },
  {
    path: ':projectId/settings',
    loader: allProjectsSettingsLoader,
    lazy: async () => {
      const { SettingsLayout } = await import('../layouts/SettingsLayout')
      return { Component: SettingsLayout }
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const Settings = await import('./settings')
          return { Component: Settings.default }
        },
      },
      {
        path: 'thirdParties',
        loader: viewPermissionLoader('THIRD_PARTY_SYNCHRONIZATION'),
        lazy: async () => {
          const ThirdParties = await import('./settings/thirdParties')
          return { Component: ThirdParties.default }
        },
      },
      {
        path: 'tasksSettings',
        loader: viewPermissionLoader('TASK'),
        lazy: async () => {
          const TaskSettings = await import('./settings/tasksSettings')
          return { Component: TaskSettings.default }
        },
      },
      {
        path: 'savedReplies',
        lazy: async () => {
          const SavedReplies = await import('./settings/savedReplies')
          return { Component: SavedReplies.default }
        },
      },
      {
        path: 'chatSettings',
        loader: devOnlyLoader,
        lazy: async () => {
          const ChatSettings = await import('./settings/chatSettings')
          return { Component: ChatSettings.default }
        },
      },
      {
        path: 'managingCompanyUsers',
        loader: viewPermissionLoader('ACCOUNTS'),
        lazy: async () => {
          const ManagingCompanyUsers = await import('./settings/managingCompanyUsers')
          return { Component: ManagingCompanyUsers.default }
        },
      },
      {
        path: 'managingCompanyRoles',
        loader: viewPermissionLoader('ROLES'),
        lazy: async () => {
          const ManagingCompanyRoles = await import('./settings/managingCompanyRoles')
          return { Component: ManagingCompanyRoles.default }
        },
      },
    ],
  },
  {
    path: ':projectId/projectSettings',
    loader: async (args) => {
      if (!args.params.projectId || args.params.projectId === 'all') {
        throw redirect('/all/settings')
      }
      await viewPermissionLoader('PROJECT')(args)
      return null
    },
    lazy: async () => {
      const { SettingsLayout } = await import('../layouts/SettingsLayout')
      return { Component: SettingsLayout }
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const ProjectSettings = await import('./projectSettings')
          return { Component: ProjectSettings.default }
        },
      },
      {
        path: 'accessKeys',
        loader: viewPermissionLoader('ACCESS_KEY'),
        lazy: async () => {
          const AccessKeys = await import('./projectSettings/accessKeys')
          return { Component: AccessKeys.default }
        },
      },
      {
        path: 'boardMembers',
        loader: isCondoProjectLoader,
        lazy: async () => {
          const BoardMembers = await import('./projectSettings/boardMembers')
          return { Component: BoardMembers.default }
        },
        children: [
          {
            path: ':residentId/edit',
            loader: viewPermissionLoader('USER'),
            lazy: async () => {
              const { EditResident } = await import('../components/drawer/residentProfile/EditResident')
              return { Component: EditResident }
            },
          },
          {
            path: ':residentId/details',
            loader: viewPermissionLoader('USER'),
            lazy: async () => {
              const { ViewResident } = await import('../components/drawer/residentProfile/ViewResident')
              return { Component: ViewResident }
            },
          },
          {
            path: 'units/:propertyId/edit',
            loader: viewPermissionLoader('UNITS'),
            lazy: async () => {
              const { EditUnit } = await import('../components/drawer/Unit/EditUnit')
              return { Component: EditUnit }
            },
          },
          {
            path: 'units/:propertyId/details',
            loader: viewPermissionLoader('UNITS'),
            lazy: async () => {
              const { ViewUnit } = await import('../components/drawer/Unit/ViewUnit')
              return { Component: ViewUnit }
            },
          },
        ],
      },
      {
        path: 'danger',
        loader: editPermissionLoader('PROJECT'),
        lazy: async () => {
          const Danger = await import('./projectSettings/danger')
          return { Component: Danger.default }
        },
      },
      {
        path: 'import',
        loader: editPermissionLoader('PROJECT'),
        lazy: async () => {
          const Import = await import('./projectSettings/import')
          return { Component: Import.default }
        },
      },
      {
        path: 'lockers',
        lazy: async () => {
          const Lockers = await import('./projectSettings/lockers')
          return { Component: Lockers.default }
        },
      },
      {
        path: 'parkings',
        lazy: async () => {
          const Parkings = await import('./projectSettings/parkings')
          return { Component: Parkings.default }
        },
      },
      {
        path: 'remotes',
        lazy: async () => {
          const Remotes = await import('./projectSettings/remotes')
          return { Component: Remotes.default }
        },
      },
    ],
  },
  {
    path: 'newProject',
    loader: viewPermissionLoader('PROJECT'),
    lazy: async () => {
      const NewProject = await import('./newProject')
      return { Component: NewProject.default }
    },
  },
  {
    path: ':projectId/dashboard/tasks',
    loader: (args) => {
      if (!args.params['*']?.includes('tasks/details')) {
        throw redirect(`/${args.params.projectId ?? ''}`)
      }
      return null
    },
    lazy: async () => {
      const Index = await import('./index')
      return { Component: Index.default }
    },
    children: [
      {
        path: 'details/:taskId',
        lazy: async () => {
          const TaskDetails = await import('../components/tasks/TaskDetails')
          return { Component: TaskDetails.default }
        },
      },
      {
        path: 'details/:taskId/units/:propertyId/details',
        lazy: async () => {
          const { ViewUnit } = await import('../components/drawer/Unit/ViewUnit')
          return { Component: ViewUnit }
        },
      },
      {
        path: 'details/:taskId/units/:propertyId/edit',
        lazy: async () => {
          const { EditUnit } = await import('../components/drawer/Unit/EditUnit')
          return { Component: EditUnit }
        },
      },
      {
        path: 'details/:taskId/residents/:residentId/edit',
        lazy: async () => {
          const { EditResident } = await import('../components/drawer/residentProfile/EditResident')
          return { Component: EditResident }
        },
      },
      {
        path: 'details/:taskId/residents/:residentId/details',
        lazy: async () => {
          const { ViewResident } = await import('../components/drawer/residentProfile/ViewResident')
          return { Component: ViewResident }
        },
      },
    ],
  },
  {
    path: ':projectId',
    lazy: async () => {
      const Main = await import('./index')
      return { Component: Main.default }
    },
  },
  {
    path: 'all',
    lazy: async () => {
      const Main = await import('./index')
      return { Component: Main.default }
    },
  },
  {
    index: true,
    lazy: async () => {
      const Main = await import('./index')
      return { Component: Main.default }
    },
  },
  {
    path: '*',
    lazy: async () => {
      const { NoMatchPage } = await import('../pages/no-match-page')
      return { Component: NoMatchPage }
    },
  },
]

const ROUTES: RouteObject[] = [
  {
    path: '*',
    element: <RootProvider />,
    errorElement: <ErrorPage />,
    loader: healthCheckLoader,
    shouldRevalidate: shouldNotRevalidate,
    children: [
      {
        element: <DashboardLayout />,
        errorElement: <ErrorPage />,
        loader: authenticationLoader,
        shouldRevalidate, // Calls the loader on every child navigation
        children: DASHBOARD_ROUTES,
      },
      {
        path: 'auth/*',
        errorElement: <ErrorPage />,
        lazy: async () => {
          const { AuthLayout } = await import('../layouts/AuthLayout')
          return { Component: AuthLayout }
        },
        children: AUTH_ROUTES,
      },
      {
        path: 'open-source-attributions',
        lazy: async () => {
          const OpenSourceAttributions = await import('./open-source-attributions')
          return { Component: OpenSourceAttributions.default }
        },
      },
      {
        path: 'temporarily-unavailable',
        lazy: async () => {
          const TemporarilyUnavailable = await import('./temporarily-unavailable')
          return { Component: TemporarilyUnavailable.default }
        },
      },
    ],
  },
]

export const router = createBrowserRouter(ROUTES)

function shouldRevalidate() {
  return true
}

function shouldNotRevalidate() {
  return false
}
