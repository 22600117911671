import { z } from 'zod'
import { i18n, t } from '../../../utils'

export const TaskCategoryi18nFields = {
  titleFr: t('form-label-title-french'),
  descriptionFr: t('description-french'),
  titleEn: t('form-label-title-english'),
  descriptionEn: t('description-english'),
}

export const taskCategorySchema = z.object({
  titleFr: z.string().nullable().optional(),
  titleEn: z.string().nullable().optional(),
  descriptionFr: z.string().nullable().optional(),
  descriptionEn: z.string().nullable().optional(),
  managingCompany: z
    .object({
      id: z.string({
        required_error: i18n.t('input-is-required', { field: t('managing-company') }),
        invalid_type_error: i18n.t('input-is-required', { field: t('managing-company') }),
      }),
    })
    .optional(),
})

export type TaskCategoryFormItem = z.infer<typeof taskCategorySchema>
