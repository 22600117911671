import { SharedUtils } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

export const ManagingCompanyUseri18nFields = {
  avatar: t('avatar'),
  firstName: t('first-name'),
  lastName: t('last-name'),
  title: t('title'),
  phone: t('phone-number'),
  officePhone: t('office-phone-number'),
  preferedLanguage: t('preferred-language'),
  email: t('email'),
  password: t('password'),
  managingCompanyRole: t('role'),
  isAvailableForTasks: t('availability'),
}

export const managingCompanyUserSchema = z.object({
  avatar: z.any().optional().nullable(),
  firstName: z.string().max(200, fieldIsTooLong(200, ManagingCompanyUseri18nFields.firstName)).optional().nullable(),
  lastName: z.string().max(200, fieldIsTooLong(200, ManagingCompanyUseri18nFields.lastName)).optional().nullable(),
  title: z.string().max(200, fieldIsTooLong(200, ManagingCompanyUseri18nFields.title)).optional().nullable(),
  phone: z
    .object({
      id: z.string().nullable().optional(),
      number: z
        .string()
        .max(11, { message: i18n.t('input-must-be-phone-number') })
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input-must-be-phone-number'),
        })
        .or(z.literal('').nullable().optional()),
    })
    .optional()
    .nullable(),
  officePhone: z
    .object({
      id: z.string().nullable().optional(),
      number: z
        .string()
        .max(11, { message: i18n.t('input-must-be-phone-number') })
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input-must-be-phone-number'),
        })
        .or(z.literal('').nullable().optional()),
    })
    .optional()
    .nullable(),
  preferedLanguage: z
    .string({
      required_error: i18n.t('input-is-required', { field: ManagingCompanyUseri18nFields.preferedLanguage }),
      invalid_type_error: i18n.t('input-is-required', { field: ManagingCompanyUseri18nFields.preferedLanguage }),
    })
    .min(1, { message: i18n.t('input-is-required', { field: ManagingCompanyUseri18nFields.preferedLanguage }) }),
  password: z.string().min(8, t('password-error-length')).or(z.literal('').optional()),
  email: z
    .string({ required_error: i18n.t('input-is-required', { field: ManagingCompanyUseri18nFields.email }) })
    .min(1, i18n.t('input-is-required', { field: ManagingCompanyUseri18nFields.email }))
    .email(t('enter-email-invalid')),
  managingCompanyRole: z.any({
    required_error: i18n.t('input-is-required', { field: ManagingCompanyUseri18nFields.managingCompanyRole }),
  }),
  notificationPreferences: z.any().optional().nullable(),
  isAvailableForTasks: z
    .enum(['true', 'false'])
    .transform((value) => value === 'true')
    .optional()
    .nullable(),
})

export const managingCompanyUserUpdateSchema = managingCompanyUserSchema.extend({ id: z.string() })

export type ManagingCompanyUser = z.infer<typeof managingCompanyUserSchema>
export type ManagingCompanyUserUpdate = z.infer<typeof managingCompanyUserUpdateSchema>
