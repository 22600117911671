import { RolePermissions, ThirdPartySyncUtils, Types } from '@walter/shared'
import { MenuItem, MenuStyles } from '@walter/shared-web'
import groupBy from 'lodash/groupBy'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AppContext from '../../contexts/App'
import AuthContext from '../../contexts/Auth'
import { useConversations } from '../../contexts/Conversations'
import PendingActionsContext from '../../contexts/PendingActions'
import { useProjectThirdPartySettingManagerWeb } from '../../hooks/useProject'
import { useProjectId } from '../../hooks/useProjectId'
import { t } from '../../utils/i18n'

export default function Menu() {
  const projectId = useProjectId()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { currentUser } = React.useContext(AuthContext)
  const { data: { project: selectedProject } = {} } = useProjectThirdPartySettingManagerWeb()
  const { pendingBookingsPerProjects } = React.useContext(PendingActionsContext)
  const { isAllProjects, currentManagingCompanyProjects } = React.useContext(AppContext)

  const { selectedProjectUnreadMessagesCount, allUnreadMessagesCount } = useConversations()

  const menus = React.useMemo(() => {
    const isVisibleFiles =
      RolePermissions.userCanSeeModule(currentUser, 'FILE') &&
      (!isAllProjects || currentManagingCompanyProjects.length > 1)

    const menus = [
      {
        ...MENUS.find(({ slug }) => slug === 'chat'),
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'CHAT'),
        notifications: isAllProjects ? allUnreadMessagesCount : selectedProjectUnreadMessagesCount,
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'TASK'),
        ...MENUS.find(({ slug }) => slug === 'tasks'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'NEWS'),
        ...MENUS.find(({ slug }) => slug === 'news'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'CALENDAR'),
        ...MENUS.find(({ slug }) => slug === 'calendar'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'RESERVATIONS') && !isAllProjects,
        ...MENUS.find(({ slug }) => slug === 'reservations'),
        notifications: pendingBookingsPerProjects[projectId] || 0,
        notificationsHintText: 'Pending bookings',
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'SALE') && !isAllProjects,
        ...MENUS.find(({ slug }) => slug === 'marketplace'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'MAIL') && !isAllProjects,
        ...MENUS.find(({ slug }) => slug === 'packages'),
      },

      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'USER'),
        ...MENUS.find(({ slug }) => slug === 'residents'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'UNITS') && !isAllProjects,
        ...MENUS.find(({ slug }) => slug === 'units'),
      },
      {
        isVisible: isVisibleFiles,
        ...MENUS.find(({ slug }) => slug === 'files'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'AMENITY') && !isAllProjects,
        ...MENUS.find(({ slug }) => slug === 'amenities'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'CONTACT'),
        ...MENUS.find(({ slug }) => slug === 'contacts'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'RULE'),
        ...MENUS.find(({ slug }) => slug === 'rules'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'FAQ'),
        ...MENUS.find(({ slug }) => slug === 'faq'),
      },
      {
        isVisible: RolePermissions.userCanSeeModule(currentUser, 'SEGMENT') && !isAllProjects,
        ...MENUS.find(({ slug }) => slug === 'groups'),
      },
      {
        isVisible:
          RolePermissions.userCanSeeModule(currentUser, 'THIRD_PARTY_SYNCHRONIZATION') &&
          ThirdPartySyncUtils.isProjectThirdParty(
            selectedProject as {
              thirdPartyServiceSettings: { mappedService: { serviceName: string }; config: any }[]
            },
          ) &&
          selectedProject?.id !== 'all',
        ...MENUS.find(({ slug }) => slug === 'thirdPartySetting'),
      },
      {
        isVisible: isAllProjects
          ? RolePermissions.userCanSeeModule(currentUser, 'MANAGING_COMPANY_INFO')
          : RolePermissions.userCanSeeModule(currentUser, 'PROJECT'),
        ...MENUS.find(({ slug }) => slug === (isAllProjects ? 'settings' : 'projectSettings')),
      },
    ]

    return menus.filter(({ isVisible }) => isVisible)
  }, [
    currentUser,
    selectedProject,
    pendingBookingsPerProjects,
    projectId,
    isAllProjects,
    selectedProjectUnreadMessagesCount,
    allUnreadMessagesCount,
  ])

  const menusBySections = React.useMemo(() => {
    return groupBy(menus, 'section')
  }, [menus])

  const currentMenu = React.useMemo(() => {
    return menus.find(({ slug }) => pathname.split('/').some((path) => path === slug))
  }, [pathname, menus])

  React.useEffect(() => {
    if (currentMenu && !currentMenu?.isVisible) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu, projectId, isAllProjects])

  return (
    <MenuStyles.MenuContainer>
      {projectId !== 'newProject' &&
        Object.keys(menusBySections).map((section) => (
          <MenuStyles.MenuSection key={section}>
            {menusBySections[section].map((menu) => (
              <MenuItem
                isActive={currentMenu?.slug === menu.slug}
                key={menu.label}
                to={`/${projectId || 'all'}${menu.slug ? `/${menu.slug}` : ''}`}
                label={menu.label ?? ''}
                icon={menu.icon as Types.IconName}
                notifications={menu.notifications}
                dataTestId={menu.dataTestId}
              />
            ))}
          </MenuStyles.MenuSection>
        ))}
    </MenuStyles.MenuContainer>
  )
}

type Menu = {
  section: number
  slug: string
  label: string
  icon: Types.IconName
  dataTestId?: string
  themeColor?: string
  notificationsHintText?: string
  availableInAllProjects?: boolean
  notifications?: number
}

export const MENUS: Menu[] = [
  {
    section: 1,
    slug: 'chat',
    label: t('nav-menu:conversations'),
    icon: 'chat',
    dataTestId: 'chat',
    notificationsHintText: 'Unanswered conversations',
  },
  {
    section: 1,
    slug: 'tasks',
    label: t('nav-menu:tasks'),
    icon: 'list',
    dataTestId: 'tasks',
  },
  {
    section: 1,
    slug: 'news',
    label: t('nav-menu:news'),
    icon: 'news',
    dataTestId: 'news',
  },
  {
    section: 1,
    slug: 'calendar',
    label: t('nav-menu:events'),
    icon: 'calendar',
    dataTestId: 'calendar',
  },
  {
    section: 1,
    slug: 'reservations',
    label: t('nav-menu:reservations'),
    icon: 'booking',
    dataTestId: 'reservations',
    notificationsHintText: 'Pending bookings',
  },
  {
    section: 1,
    slug: 'marketplace',
    label: t('nav-menu:marketplace'),
    icon: 'shop',
    dataTestId: 'marketplace',
  },
  {
    section: 1,
    slug: 'packages',
    label: t('nav-menu:packages'),
    icon: 'package',
    dataTestId: 'packages',
  },
  {
    section: 2,
    slug: 'residents',
    label: t('tenants-uppercase'),
    dataTestId: 'residents',
    icon: 'group',
  },
  {
    section: 2,
    slug: 'units',
    label: t('nav-menu:units'),
    icon: 'house',
    dataTestId: 'units',
    availableInAllProjects: false,
  },
  {
    section: 2,
    slug: 'files',
    label: t('nav-menu:files'),
    dataTestId: 'files',
    icon: 'folder',
  },
  {
    section: 2,
    slug: 'amenities',
    label: t('nav-menu:amenities'),
    icon: 'pool',
    dataTestId: 'amenities',
    availableInAllProjects: false,
  },
  {
    section: 2,
    slug: 'contacts',
    label: t('nav-menu:contacts'),
    dataTestId: 'contacts',
    icon: 'phone',
  },
  {
    section: 2,
    slug: 'rules',
    label: t('nav-menu:rules'),
    dataTestId: 'rules',
    icon: 'compose',
  },
  {
    section: 2,
    slug: 'faq',
    label: t('nav-menu:faq'),
    dataTestId: 'faq',
    icon: 'help',
  },
  {
    section: 2,
    slug: 'groups',
    label: t('nav-menu:groups'),
    dataTestId: 'groups',
    icon: 'filter',
    availableInAllProjects: false,
  },
  {
    section: 2,
    slug: 'thirdPartySetting',
    label: t('nav-menu:third-party-data'),
    dataTestId: 'thirdPartySetting',
    icon: 'wrench',
    availableInAllProjects: false,
  },
  {
    section: 3,
    slug: 'settings',
    label: t('nav-menu:settings'),
    dataTestId: 'settings',
    icon: 'settings',
  },
  {
    section: 3,
    slug: 'projectSettings',
    label: t('nav-menu:settings'),
    dataTestId: 'projectSettings',
    icon: 'projectSettings',
  },
]
