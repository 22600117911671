import { Api, RolePermissions } from '@walter/shared'
import {
  Avatar,
  Button,
  ButtonGroup,
  Modal,
  NavStyled,
  Popover,
  PopoverAlignment,
  PopoverContext,
  ProfileMenu,
} from '@walter/shared-web'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../contexts/App'
import AuthContext from '../../contexts/Auth'
import { useProjectId } from '../../hooks/useProjectId'
import { t } from '../../utils/i18n'
import NotificationsAction from './NotificationAction'
import ProjectSelector from './ProjectSelector'

export default function Nav() {
  const navigate = useNavigate()
  const projectId = useProjectId()
  const { isAllProjects, currentManagingCompanyProjects } = useContext(AppContext)
  const { currentUser: user, logout } = useContext(AuthContext)
  const { setPopoverVisible: setPopoverContentVisible } = useContext(PopoverContext)
  const [isHelpModalVisible, setIsHelpModalVisible] = React.useState<boolean>(false)

  const currentProject = React.useMemo(() => {
    return currentManagingCompanyProjects.find(({ id }) => projectId === id)
  }, [projectId, currentManagingCompanyProjects])

  const projectName = React.useMemo(() => {
    if (isAllProjects) {
      return t('all-projects')
    }

    if (!currentProject) {
      return ''
    }

    const nameOfProject = currentProject.name ?? ''

    if (nameOfProject.length <= 40) {
      return currentProject.name
    }

    // To show something like: "Syndicat des ... 1500 Mile end"
    return `${nameOfProject.substr(0, (nameOfProject.length - 5) / 2)} ... ${nameOfProject.substr(
      nameOfProject.length - (nameOfProject.length - 5) / 2,
      nameOfProject.length,
    )}`
  }, [currentProject, user, isAllProjects, projectId])

  return (
    <>
      <NavStyled.Container data-test-id="Nav_Container">
        <NavStyled.Wrap>
          <NavStyled.Group data-test-id="Nav_Left">
            <NavStyled.Header data-test-id="Nav_Header">
              <NavStyled.Name
                data-test-id="CompanyName"
                onClick={() =>
                  RolePermissions.hasAtLeastOnePermissions(user, [
                    Api.Permission_Type.ManagingCompanyInfoRead,
                    Api.Permission_Type.ManagingCompanyInfoWrite,
                  ]) && navigate(`/all/settings`)
                }
                data-cy={user?.managingCompany?.shortName}
              >
                {user?.managingCompany?.shortName}
              </NavStyled.Name>
              <NavStyled.Project data-test-id="Project" data-cy={`project-${projectName}`}>
                {projectName}
              </NavStyled.Project>
            </NavStyled.Header>
            <NavStyled.NavList data-test-id="Nav_List">
              <ProjectSelector />
            </NavStyled.NavList>
          </NavStyled.Group>
          <NavStyled.Group data-test-id="Nav_Right">
            <Button
              dataTestId="Dashboard_Button"
              testID="Dashboard"
              size="small"
              theme="tertiary"
              onClick={() => navigate(`/${projectId || 'all'}`)}
            >
              {t('general:dashboard')}
            </Button>
            <NotificationsAction />
            <NavStyled.Profile data-test-id="Profile_Button">
              <Popover
                dataTestId="Profile_Popover"
                trigger={({ onClick }, forwardRef) => (
                  <NavStyled.AvatarWrap ref={forwardRef as React.Ref<HTMLButtonElement>} onClick={onClick}>
                    <Avatar photo={user?.avatar?.url} name={`${user?.firstName} ${user?.lastName}`} />
                  </NavStyled.AvatarWrap>
                )}
                alignment={PopoverAlignment.RIGHT}
              >
                {user && (
                  <ProfileMenu
                    user={user}
                    logout={async () => {
                      localStorage.clear()
                      await logout()
                      navigate('/auth/login')
                    }}
                    actions={[
                      {
                        dataTestId: 'MyProfile',
                        label: t('general:my-profile'),
                        onClick: () => {
                          setPopoverContentVisible(false)
                          navigate(`/${projectId || 'all'}/profile`)
                        },
                      },
                      {
                        dataTestId: 'CompanyInfo',
                        label: t('general:company-info'),
                        onClick: () => {
                          setPopoverContentVisible(false)
                          navigate(`/all/settings`)
                        },
                      },
                      {
                        dataTestId: 'Help',
                        label: t('general:help'),

                        onClick: () => {
                          setIsHelpModalVisible(true)
                          setPopoverContentVisible(false)
                        },
                      },
                    ]}
                  />
                )}
              </Popover>
            </NavStyled.Profile>
          </NavStyled.Group>
        </NavStyled.Wrap>
      </NavStyled.Container>

      <Modal
        icon="help"
        title={t('need-help')}
        visible={isHelpModalVisible}
        dataTestId="Help_Modal"
        close={() => setIsHelpModalVisible(false)}
      >
        <p>
          {t('for-any-assistance-please-access-the') + ' '}
          <a href="https://portail.hopem.com/" target="_blank" rel="noreferrer">
            {' ' + t('client-portal')}
          </a>

          {' ' + t('or-send-an-email-to') + ' '}
          <a href="mailto:support@hopem.com" target="_blank" rel="noreferrer">
            support@hopem.com
          </a>
        </p>

        <ButtonGroup>
          <Button onClick={() => setIsHelpModalVisible(false)} dataTestId="Close_help_button" theme="primary">
            {t('close')}
          </Button>
        </ButtonGroup>
      </Modal>
    </>
  )
}
