import { z } from 'zod'
import { i18n, t } from '../../utils'

export const ForgotPasswordi18nFields = {
  email: t('email-address'),
}

export const ForgotPasswordSchema = z.object({
  email: z
    .string({ required_error: i18n.t('input-is-required', { field: ForgotPasswordi18nFields.email }) })
    .min(1, i18n.t('input-is-required', { field: ForgotPasswordi18nFields.email }))
    .email(t('enter-email-invalid')),
})

export type ForgotPassword = z.infer<typeof ForgotPasswordSchema>
