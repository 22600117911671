import { SharedUtils } from '@walter/shared'
import isInteger from 'lodash/isInteger'
import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Contacti18nFields = {
  projects: t('selected-project'),
  isPrivate: t('contact-is-private'),
  isEmergencyContact: t('contact-emergency-contact'),
  firstName: t('first-name'),
  lastName: t('last-name'),
  title: t('title'),
  email: t('email'),
  phone: t('phone-number'),
  extension: t('extension'),
  website: t('website'),
}

export const ContactSchema = z
  .object({
    id: z.string().nullable().optional(),
    projects: z
      .array(z.any(), {
        required_error: i18n.t('input-is-required', { field: Contacti18nFields.projects }),
      })
      .min(1, i18n.t('input-is-required', { field: Contacti18nFields.projects })),
    isPrivate: z.boolean().optional().nullable(),
    isEmergencyContact: z.boolean().optional().nullable(),
    firstName: z
      .string({ required_error: i18n.t('input-is-required', { field: Contacti18nFields.firstName }) })
      .min(1, { message: i18n.t('input-is-required', { field: Contacti18nFields.firstName }) }),
    lastName: z
      .string({ required_error: i18n.t('input-is-required', { field: Contacti18nFields.lastName }) })
      .min(1, { message: i18n.t('input-is-required', { field: Contacti18nFields.lastName }) }),
    title: z.string().optional().nullable(),
    email: z
      .string({ required_error: i18n.t('input-is-required', { field: Contacti18nFields.email }) })
      .email({ message: t('invalid-email') })
      .or(z.literal('').nullable().optional()),
    phone: z.object({
      number: z
        .string({ required_error: i18n.t('input-is-required', { field: Contacti18nFields.phone }) })
        .max(11, { message: i18n.t('input-must-be-phone-number') })
        .regex(SharedUtils.phoneNumberRegex, {
          message: i18n.t('input-must-be-phone-number'),
        })
        .or(z.literal('').nullable().optional()),
      extension: z
        .string()
        .nullable()
        .optional()
        .refine(
          (value) => {
            return (value?.trim().length ?? 0) > 0 && typeof value === 'string'
              ? isInteger(parseInt(value?.trim()))
              : true
          },
          {
            message: t('invalid-extension-number'),
          },
        ),
    }),
    website: z.string().nullable().optional(),
  })
  .refine((data) => data.email || data.phone?.number, {
    message: i18n.t('enter-either-phone-or-email'),
    path: ['email'],
  })

export type ContactFormType = z.infer<typeof ContactSchema>
