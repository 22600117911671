import React from 'react'
import styled from 'styled-components'
import { Colors, Spacing } from '@walter/shared'
import { stripUnit } from 'polished'
import { animationCurve, animationTime, controlDimensions, square } from '../../styles/global'

const Container = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  justify-content: center;
`

const Control = styled.div`
  flex-shrink: 0;
  display: flex;
`

const Input = styled.input.attrs({ type: 'radio' })`
  display: none;
`

const Indicator = styled.div`
  ${square(controlDimensions)};
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderColor};
  margin-top: 3px; /* Align text with checkbox */
  transition: background-color ${animationTime} ${animationCurve}, border-color ${animationTime} ${animationCurve};

  ${Input}:checked + & {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }
`

const Dot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};
  background-color: ${Colors.white};
  border-radius: 50%;
  ${square('6px')};

  ${Input}:checked ~ ${Indicator} & {
    opacity: 1; /* Show icon when radio is checked */
    visibility: visible;
  }
`

const Label = styled.div`
  text-align: left;
  margin-left: ${`${(stripUnit(Spacing.small) as number) * 1.5}px`};
`

type RadioProps = {
  label?: string
  name: string
  checked?: boolean
  disabled?: boolean
  onChange?: (event: any) => void
  dataTestId?: string
  style?: React.CSSProperties
}

export const Radio = ({ label, checked, name, disabled, onChange, dataTestId, style }: RadioProps) => {
  return (
    <Container style={style}>
      <Control>
        <Input name={name} onChange={onChange} checked={checked} disabled={disabled} data-test-id={dataTestId} />
        <Indicator>
          <Dot />
        </Indicator>
      </Control>
      {label && <Label>{label}</Label>}
    </Container>
  )
}
