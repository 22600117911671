import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Taski18nFields = {
  associatedProject: t('project'),
  title: t('title'),
  categoryId: t('task-type'),
  statusId: t('status'),

  assignToIds: t('managers'),
  associateTo: t('associateTo'),

  priorityStatus: t('priority'),
  description: t('description'),

  attachments: t('files'),
  pictures: t('images'),

  dueDate: t('task-due-date'),

  scheduledDateStart: t('task-scheduled-date-start'),
  scheduledDateEnd: t('task-scheduled-date-end'),

  comment: t('comment'),
}

export const taskSchema = z
  .object({
    associatedProject: z.object(
      {
        id: z.string({ required_error: i18n.t('input-is-required', { field: Taski18nFields.associatedProject }) }),
      },
      {
        required_error: i18n.t('input-is-required', { field: Taski18nFields.associatedProject }),
        invalid_type_error: i18n.t('input-is-required', { field: Taski18nFields.associatedProject }),
      },
    ),
    title: z.string().nullable().optional(),
    description: z.string().nullable().optional(),
    category: z.object(
      {
        id: z
          .string({ required_error: i18n.t('input-is-required', { field: Taski18nFields.categoryId }) })
          .min(1, { message: i18n.t('input-is-required', { field: Taski18nFields.categoryId }) }),
      },
      {
        required_error: i18n.t('input-is-required', { field: Taski18nFields.categoryId }),
        invalid_type_error: i18n.t('input-is-required', { field: Taski18nFields.categoryId }),
      },
    ),
    status: z.object(
      {
        id: z
          .string({ required_error: i18n.t('input-is-required', { field: Taski18nFields.statusId }) })
          .min(1, { message: i18n.t('input-is-required', { field: Taski18nFields.statusId }) }),
      },
      {
        invalid_type_error: i18n.t('input-is-required', { field: Taski18nFields.statusId }),
        required_error: i18n.t('input-is-required', { field: Taski18nFields.statusId }),
      },
    ),
    assignedToIds: z.array(z.object({ id: z.string() })).optional(),
    associatedProperties: z.array(z.object({ id: z.string() })).optional(),
    associatedResidents: z.array(z.object({ id: z.string() })).optional(),
    associatedSegments: z.array(z.object({ id: z.string() })).optional(),
    priorityStatus: z.object({ id: z.string() }).optional(),
    attachments: z.any().optional(),
    pictures: z.any().optional(),
    dueDate: z.string().or(z.date().optional().nullable()),
    scheduledDateStart: z.string().or(z.date().optional().nullable()),
    scheduledDateEnd: z.string().or(z.date().optional().nullable()),
  })
  .refine((data) => !!data.title || !!data.description, {
    message: t('title-or-description-is-required'),
    path: ['title'],
  })

export const taskUpdateSchema = z.object({
  attachments: z.any().optional(),
  pictures: z.any().optional(),
  comment: z.any().optional(),
})

export type TaskFormSchema = z.infer<typeof taskSchema>
export type TaskUpdateFormSchema = z.infer<typeof taskUpdateSchema>
