import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Project18nFields = {
  name: t('project-name'),
  colorHex: t('brand-color'),
  logo: t('horizontal-logo'),
  squareLogo: t('project-avatar'),
  nameInitials: t('project-initials'),

  address1: t('address-line-1'),
  address2: t('address-line-2'),
  apartmentNumber: t('unit-number'),
  city: t('city'),
  zip: t('postal-code'),
  state: t('province-state'),
  country: t('country'),
  timezone: t('timezone'),

  numberOfProperties: t('number-of-units'),
  additionalInfo: t('additional-information'),
  isCondoProject: t('is-condo-project'),
  isRentalProject: t('is-rental-project'),
  isInConstruction: t('project-is-in-construction'),

  residentCanCreateTask: t('tenant-can-create-task'),
  residentCanEditFirstName: t('tenant-can-edit-their-first-name'),
  residentCanEditLastName: t('tenant-can-edit-their-last-name'),
  residentCanEditEmail: t('tenant-can-edit-their-email'),
  residentCanEditPhoneNumber: t('tenant-can-edit-their-phone-number'),

  news: t('news'),
  calendar: t('events'),
  contact: t('contacts'),
  rule: t('rule'),
  amenity: t('amenities'),
  parcel: t('parcel'),
  chat: t('chat'),
  files: t('files'),
  marketplace: t('marketplace'),
  tasks: t('tasks'),
  faq: t('faq'),
}

export const ProjectSchema = z.object({
  name: z
    .string({ required_error: i18n.t('input-is-required', { field: Project18nFields.name }) })
    .min(1, { message: i18n.t('input-is-required', { field: Project18nFields.name }) }),
  colorHex: z.string().optional().nullable(),
  logo: z.any().optional().nullable(),
  squareLogo: z.any().optional().nullable(),
  nameInitials: z.string().optional().nullable(),

  building: z
    .object({
      address: z.object({
        address1: z.string().optional().nullable(),
        address2: z.string().optional().nullable(),
        apartmentNumber: z.string().optional().nullable(),
        city: z.string().optional().nullable(),
        country: z.string().optional().nullable(),
        countryCode: z.string().optional().nullable(),
        provinceCode: z.string().optional().nullable(),
        state: z.string().optional().nullable(),
        zip: z.string().optional().nullable(),
      }),
    })
    .optional()
    .nullable(),
  timezone: z.object({ label: z.string(), value: z.string() }).or(z.string()).optional().nullable(),

  numberOfProperties: z.string().or(z.number().optional().nullable()),
  additionalInfo: z.string().optional().nullable(),
  isCondoProject: z.boolean().optional().nullable().default(false),
  isRentalProject: z.boolean().optional().nullable().default(false),
  isInConstruction: z.boolean().optional().nullable().default(false),

  residentCanCreateTask: z.boolean().optional().nullable(),
  residentCanEditFirstName: z.boolean().optional().nullable(),
  residentCanEditLastName: z.boolean().optional().nullable(),
  residentCanEditEmail: z.boolean().optional().nullable(),
  residentCanEditPhoneNumber: z.boolean().optional().nullable(),

  tools: z.array(z.string()),
})

export type ProjectSchemaType = z.infer<typeof ProjectSchema>
