import { z } from 'zod'
import { i18n, t } from '../../utils'

export const Logini18nFields = {
  email: t('email-address'),
  password: t('login-page-password'),
}

export const LoginSchema = z.object({
  email: z
    .string({ required_error: i18n.t('input-is-required', { field: Logini18nFields.email }) })
    .min(1, i18n.t('input-is-required', { field: Logini18nFields.email }))
    .email(t('enter-email-invalid')),
  password: z
    .string({ required_error: i18n.t('input-is-required', { field: Logini18nFields.password }) })
    .min(8, t('password-error-length')),
})

export type Login = z.infer<typeof LoginSchema>
