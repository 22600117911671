import { SharedUtils } from '@walter/shared'
import { z } from 'zod'
import { i18n, t } from '../../utils'
import { fieldIsTooLong } from './common'

// eslint-disable-next-line no-irregular-whitespace
// Space character used by Intl.NumberFormat. Ex: 10 000,00
const NARROW_NO_BREAK_SPACE_CHAR = String.fromCharCode(8239)

export const Marketplacei18nFields = {
  title: t('title'),
  description: t('description'),
  price: t('price'),
  phone: t('mobile-phone-number'),
  email: t('email'),
  images: t('photos'),
}

export const MarketplaceSchema = z.object({
  title: z
    .string({ required_error: i18n.t('input-is-required', { field: Marketplacei18nFields.title }) })
    .min(1, { message: i18n.t('input-is-required', { field: Marketplacei18nFields.title }) })
    .max(200, fieldIsTooLong(200, Marketplacei18nFields.title)),
  description: z.string().optional().nullable(),
  price: z
    .number()
    .or(
      z
        .string()
        .transform((val) => {
          const value = val?.replace(',', '.').replaceAll(NARROW_NO_BREAK_SPACE_CHAR, '')
          return Number(value)
        })
        .refine(
          (val) => {
            return !isNaN(val) && val < 9_999_999 && val >= 0
          },
          { message: i18n.t('input-must-be-positive-number', { field: t('price') }) },
        ),
    )
    .optional(),
  phone: z
    .string()
    .max(11, { message: i18n.t('input-must-be-phone-number') })
    .regex(SharedUtils.phoneNumberRegex, {
      message: i18n.t('input-must-be-phone-number'),
    })
    .or(z.literal('').nullable().optional()),
  email: z
    .string({ required_error: i18n.t('input-is-required', { field: Marketplacei18nFields.email }) })
    .min(1, i18n.t('input-is-required', { field: Marketplacei18nFields.email }))
    .email(t('enter-email-invalid'))
    .or(z.literal('').nullable().optional()),
  images: z.any().optional().nullable(),
})

export const MarketplaceUpdateSchema = MarketplaceSchema.extend({ id: z.string() })

export type Marketplace = z.infer<typeof MarketplaceSchema>
