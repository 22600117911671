import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { useEffect, useMemo } from 'react'
import { Channel, MessagingService } from '../MessagingService'
import {
  AllowedProject,
  allowedProjectsForManagerAtom,
  conversationsSearchValueAtom,
  initialLoadingAtom,
  privateChannelsMapAtom,
  publicChannelsMapAtom,
} from '../atoms'

export interface ConversationGroup {
  title: string
  data: Channel[]
}

interface UseManagerConversationsArgs {
  allowedProjects: AllowedProject[]
  selectedProjectId?: string
  selectedProjects?: AllowedProject[]
  typeFilter?: 'dm' | 'unit'
}

export const isSortingConversationsAtom = atom(false)

/**
 * @warning Must be called after `useMessagingService`
 * @returns Grouped/sorted conversations and utilities
 */
export const useManagerConversations = ({
  selectedProjectId,
  allowedProjects,
  selectedProjects,
  typeFilter,
}: UseManagerConversationsArgs) => {
  const setAllowedValue = useSetAtom(allowedProjectsForManagerAtom)
  const isLoading = useAtomValue(initialLoadingAtom)
  const publicChannelsMap = useAtomValue(publicChannelsMapAtom)
  const privateChannelsMap = useAtomValue(privateChannelsMapAtom)
  const [searchValue, setSearchValue] = useAtom(conversationsSearchValueAtom)
  const [isSortingConversations, setIsSortingConversations] = useAtom(isSortingConversationsAtom)

  useEffect(() => {
    setAllowedValue(allowedProjects)
  }, [allowedProjects])

  const publicChannels = useMemo(() => Array.from(publicChannelsMap.values()) as Channel[], [publicChannelsMap])

  const channelsByProject = useMemo(() => {
    return groupBy(publicChannels, (channel) => {
      const privateChannel = privateChannelsMap.get(channel.url.concat('-private'))
      channel.unreadMessageCount += privateChannel?.unreadMessageCount ?? 0
      return MessagingService.projectIdFromChannelUrl(channel.url)
    })
  }, [publicChannels, privateChannelsMap])

  const unitNumbersByProjectIdByResidentIdMap = useMemo(() => {
    const apartmentNumbersByProjectIdByResidentIdMap = new Map<string, Map<string, string[]>>()
    for (const channel of publicChannels) {
      if (MessagingService.channelIsUnit(channel.url)) {
        for (const member of channel.members) {
          if (member?.metaData?.role === 'RESIDENT') {
            const projectId = MessagingService.projectIdFromChannelUrl(channel.url)
            const apartmentNumbersByResidentId = apartmentNumbersByProjectIdByResidentIdMap.get(projectId) ?? new Map()
            const aptNumbers = apartmentNumbersByResidentId.get(member.userId) ?? []
            apartmentNumbersByResidentId.set(member.userId, aptNumbers.concat(channel.name))
            apartmentNumbersByProjectIdByResidentIdMap.set(projectId, apartmentNumbersByResidentId)
          }
        }
      }
    }

    return apartmentNumbersByProjectIdByResidentIdMap
  }, [publicChannels])

  const conversations = useMemo(() => {
    const channels: Channel[] = []
    if (!allowedProjects || allowedProjects.length === 0) return channels
    setIsSortingConversations(true)

    const projectMap = new Map()
    const projects = selectedProjects && selectedProjects.length > 0 ? selectedProjects : allowedProjects
    for (const project of projects) {
      projectMap.set(project.id, project)
      const convos = channelsByProject[project.id] ?? []
      channels.push(...convos)
    }

    const orderedChannels = orderBy(
      channels,
      [
        (channel) => channel?.unreadMessageCount ?? 0,
        (channel) => channel?.lastMessage?.createdAt ?? 0,
        (channel) => channel?.name,
      ],
      ['desc', 'desc', 'asc'],
    )

    function inNameUnitOrProject(channel: Channel) {
      const inName = channel.name?.toLocaleLowerCase().includes(searchValue.toLowerCase())
      const unitNumbersByResidentIdMap =
        unitNumbersByProjectIdByResidentIdMap.get(MessagingService.projectIdFromChannelUrl(channel.url)) ?? new Map()
      const inUnit = MessagingService.channelIsDm(channel.url)
        ? unitNumbersByResidentIdMap
            .get(MessagingService.entityIdFromChannelUrl(channel.url))
            ?.join(', ')
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        : false

      const projectName = projectMap.get(MessagingService.projectIdFromChannelUrl(channel.url))?.name ?? ''
      const inProject = projectName.toLowerCase().includes(searchValue.toLowerCase())

      return inName || inUnit || inProject
    }

    function byType(channel: Channel) {
      if (typeFilter === 'dm') return MessagingService.channelIsDm(channel.url)
      if (typeFilter === 'unit') return MessagingService.channelIsUnit(channel.url)
      return true
    }

    function filter(channel: Channel) {
      return inNameUnitOrProject(channel) && byType(channel)
    }

    setIsSortingConversations(false)
    return orderedChannels.filter(filter)
  }, [
    channelsByProject,
    searchValue,
    unitNumbersByProjectIdByResidentIdMap,
    allowedProjects,
    selectedProjects,
    typeFilter,
  ])

  const conversationsByProject = useMemo(() => {
    const unreads: Channel[] = []
    const groups: ConversationGroup[] = []
    if (!allowedProjects || allowedProjects.length === 0) return groups
    setIsSortingConversations(true)

    const projects = selectedProjects && selectedProjects.length > 0 ? selectedProjects : allowedProjects
    for (const project of projects) {
      const channels = channelsByProject[project.id] ?? []
      const unreadChannels = channels.filter((channel) => {
        const hasUnreadMessages = channel.unreadMessageCount > 0
        if (hasUnreadMessages) unreads.push(channel)
        return !hasUnreadMessages
      })
      groups.push({
        title: project.name ?? project.id,
        data: orderBy(
          unreadChannels,
          [
            (channel) => channel.unreadMessageCount,
            (channel) => channel.lastMessage?.createdAt || 0,
            (channel) => channel.name,
          ],
          ['desc', 'desc', 'asc'],
        ),
      })
    }

    if (unreads.length > 0) {
      groups.push({
        title: 'notifications-unread',
        data: orderBy(
          unreads,
          [
            (channel) => channel.unreadMessageCount,
            (channel) => channel.lastMessage?.createdAt || 0,
            (channel) => channel.name,
          ],
          ['desc', 'desc', 'asc'],
        ),
      })
    }

    const orderedConversationGroups = orderBy(
      groups,
      [
        (group) => group.data[0]?.unreadMessageCount ?? 0,
        (group) => group.data[0]?.lastMessage?.createdAt ?? 0,
        (group) => group.title,
      ],
      ['desc', 'desc', 'asc'],
    )

    function inNameOrUnit(channel: Channel) {
      const inName = channel.name?.toLocaleLowerCase().includes(searchValue.toLowerCase())
      const unitNumbersByResidentIdMap =
        unitNumbersByProjectIdByResidentIdMap.get(MessagingService.projectIdFromChannelUrl(channel.url)) ?? new Map()
      const inUnit = MessagingService.channelIsDm(channel.url)
        ? unitNumbersByResidentIdMap
            .get(MessagingService.entityIdFromChannelUrl(channel.url))
            ?.join(', ')
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        : false

      return inName || inUnit
    }

    const result = searchValue
      ? orderedConversationGroups
          // Fitlers projects
          .filter((group) => group.data.some(inNameOrUnit))
          // Filters channels within project
          .map((group) => ({
            title: group.title,
            data: group.data.filter(inNameOrUnit),
          }))
      : orderedConversationGroups
    setIsSortingConversations(false)
    return result
  }, [
    channelsByProject,
    searchValue,
    unitNumbersByProjectIdByResidentIdMap,
    allowedProjects,
    selectedProjectId,
    selectedProjects,
  ])

  const conversationsByType = useMemo(() => {
    const unreads: Channel[] = []
    const dm: Channel[] = []
    const unit: Channel[] = []
    setIsSortingConversations(true)
    const conversations = orderBy(
      selectedProjectId ? channelsByProject[selectedProjectId] ?? [] : [],
      [
        (channel) => channel.unreadMessageCount,
        (channel) => channel.lastMessage?.createdAt || 0,
        (channel) => channel.name,
      ],
      ['desc', 'desc', 'asc'],
    )

    for (const conversation of conversations) {
      if (conversation.unreadMessageCount > 0) unreads.push(conversation)
      else if (MessagingService.channelIsDm(conversation.url)) dm.push(conversation)
      else if (MessagingService.channelIsUnit(conversation.url)) unit.push(conversation)
    }

    const dms = searchValue
      ? dm.filter((channel) => {
          const unitNumbersByResidentIdMap =
            unitNumbersByProjectIdByResidentIdMap.get(MessagingService.projectIdFromChannelUrl(channel.url)) ??
            new Map()

          return (
            channel.name?.toLocaleLowerCase().includes(searchValue.toLowerCase()) ||
            unitNumbersByResidentIdMap
              .get(MessagingService.entityIdFromChannelUrl(channel.url))
              ?.join(', ')
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
        })
      : dm

    const units = searchValue
      ? unit.filter((channel) => channel.name?.toLocaleLowerCase().includes(searchValue.toLowerCase()))
      : unit

    setIsSortingConversations(false)
    return [
      {
        title: 'notifications-unread',
        data: unreads,
      },
      {
        title: 'tenants-uppercase',
        data: dms,
      },
      {
        title: 'units',
        data: units,
      },
    ]
  }, [channelsByProject, searchValue, unitNumbersByProjectIdByResidentIdMap, selectedProjectId])

  return {
    isLoading,
    groups: selectedProjectId ? conversationsByType : conversationsByProject,
    conversations,
    unitNumbersByProjectIdByResidentIdMap,
    searchValue,
    setSearchValue,
    isSortingConversations,
  }
}
