import { Api } from '@walter/shared'
import { z } from 'zod'
import { WebDateUtils, i18n, t } from '../../utils'
import { emptyStringToNull } from './transformationUtils'

export const eventi18nFields = {
  title: t('fields-event:title'),
  description: t('fields-event:description'),
  type: t('fields-event:type-of-event'),
  start: t('start'),
  end: t('end'),
  coverImage: t('fields-event:cover-image'),
  hasReminder: t('fields-event:has-reminder'),
  typePublic: t('fields-event:for-tenants'),
  typePrivate: t('fields-event:only-for-managing-company'),
  segments: t('fields-segment:group'),
  repeatType: t('repeat-type'),
  repeatedXTimes: t('repeated-x-times'),
}

export const eventSchemaCreate = z
  .object({
    type: z.nativeEnum(Api.Event_Type, {
      required_error: i18n.t('input-is-required', { field: eventi18nFields.type }),
    }),
    title: z
      .string({ required_error: i18n.t('input-is-required', { field: eventi18nFields.title }) })
      .min(1, { message: i18n.t('input-is-required', { field: eventi18nFields.title }) }),
    description: z.string().nullable().optional(),
    start: z.date({ required_error: i18n.t('input-is-required', { field: eventi18nFields.start }) }),
    end: z.date({ required_error: i18n.t('input-is-required', { field: eventi18nFields.end }) }),
    coverImage: z.any(),
    hasAlertReminder: z.boolean().nullable().default(false),
    segments: z.array(z.object({ id: z.string() })),
    repeatType: z.string().nullable().optional(),
    repeatedXTimes: z
      .string()
      .transform(emptyStringToNull)
      .or(z.number().positive())
      .nullable()
      .optional()
      .refine((data) => !data || data < 367, { message: t('max-repetitions-is-366') }),
  })
  .refine(
    (data) => {
      if (data.end && data.start) {
        return data.end.getTime() >= data.start.getTime()
      }
      return true
    },
    {
      message: t('end-must-be-after-start'),
      path: ['end'],
    },
  )

export type Event = z.infer<typeof eventSchemaCreate> & { type: Api.Event_Type }

export const calendarEventType = [Api.Event_Type.Private, Api.Event_Type.Public]
export const eventTypeOptions = [
  {
    label: t('fields-event:for-tenants'),
    value: 'PUBLIC',
  },
  {
    label: t('fields-event:only-for-managing-company'),
    value: 'PRIVATE',
  },
]
export const eventRepeatTypeOptions = (date: Date) => [
  {
    label: t('not-repeated'),
    value: 'NOT_REPEATED',
  },
  {
    label: t('repeated-daily'),
    value: 'DAILY',
  },
  {
    label: t('repeated-weekly', { dayOfWeek: WebDateUtils.date(date).format('dddd') }),
    value: 'WEEKLY',
  },
  {
    label: t('repeated-monthly', { date: WebDateUtils.date(date).format('LL') }),
    value: 'MONTHLY',
  },
  {
    label: t('repeated-yearly', {
      date: WebDateUtils.date(date).format('LL'),
    }),
    value: 'YEARLY',
  },
]
