import { Config } from './../config/index'
type FileUploadType = File & {
  path?: string
  preview?: string
}

function getServerlessHost() {
  // functions are generated from netlify
  // so we point to the actual netlify domain on development
  return Config.isProduction ? `https://manager.hopem.com` : `https://manager-staging.hopem.com`
}

export async function serverlessUpload(file: FileUploadType) {
  const [t, extension] = file.type ? file.type.split('/') : file.name.split('.')

  const signedURLresponse = await fetch(`${getServerlessHost()}/.netlify/functions/upload`, {
    method: 'POST',
    body: JSON.stringify({ name: file.path || `${t}.${extension}`, type: file.type }),
  })

  const signedURLresponseJSON = await signedURLresponse.json()

  const uploadResponse = await fetch(`${signedURLresponseJSON.signedUrl}`, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*',
    },
  })

  const makePublicResponse = await fetch(
    `${getServerlessHost()}/.netlify/functions/upload?filepath=${signedURLresponseJSON.path}`,
    {
      method: 'GET',
    },
  )

  const makePueblicResponseJSON = await makePublicResponse.json()

  const [bucketResponse] = makePueblicResponseJSON

  if (uploadResponse.status !== 200) {
    throw new Error('Upload failed')
  }

  return {
    url: signedURLresponseJSON.publicURL,
    test: 'ok',
    ...bucketResponse,
  }
}
