import { z } from 'zod'
import { i18n, t } from '../../../utils'
import { fieldIsTooLong } from './../common'

export const SavedRepliesi18nFields = {
  title: t('title'),
  message: t('message'),
}

export const savedRepliesSchema = z.object({
  id: z.number().min(0).optional(),
  title: z
    .string({ required_error: i18n.t('input-is-required', { field: SavedRepliesi18nFields.title }) })
    .min(1, { message: i18n.t('input-is-required', { field: SavedRepliesi18nFields.title }) })
    .max(200, fieldIsTooLong(200, SavedRepliesi18nFields.message)),
  message: z
    .string({ required_error: i18n.t('input-is-required', { field: SavedRepliesi18nFields.message }) })
    .min(1, { message: i18n.t('input-is-required', { field: SavedRepliesi18nFields.message }) })
    .max(200, fieldIsTooLong(200, SavedRepliesi18nFields.message)),
})

export type SavedReplies = z.infer<typeof savedRepliesSchema>
